import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import SharedLib from 'wizarding-world-web-shared';
import { useGlobalState as useSearchGlobalState } from '@/src/globalStates/SearchState';
import { useWindowWidth } from '@hooks/windowHooks';
import { breakpoints } from '@hooks/useBreakpoints';
import { REGISTER } from '@constants/routes';
import { trackClickEvent } from '@utils/analytics';
import { SIGNUP_NOTIFICATION_SEEN } from '@constants/index';
import RenderAnimation from '@components/RenderAnimation';
import buttonAnimation from '../../animations/signup_button_desktop.json';
import buttonAnimationMobile from '../../animations/signup_button_mobile.json';
import SignUpPanel from '../SignUpPanel';
import s from './SignUpBtn.module.scss';

const SignUpBtn = () => {
  const router = useRouter();
  const { getCookie } = SharedLib.utils.cookie;
  const width = useWindowWidth();
  const isMobile = width < breakpoints.md;
  const [isSearching] = useSearchGlobalState('isSearching');

  const [isButtonOutlineAnimationComplete, setButtonOutlineAnimationComplete] = useState(false);
  const [isButtonFillAnimationComplete, setButtonFillAnimationComplete] = useState(false);
  const [isPanelAnimationComplete, setPanelAnimationComplete] = useState(false);
  const [segmentRange, setSegmentRange] = useState([0, 28]);
  const [isSignUpHovered, setSignUpHovered] = useState(false);
  const [signupNotificationSeen, setSignupNotificationSeen] = useState(null);
  const [isDelayed, setDelayed] = useState(true);

  useEffect(() => {
    setSignupNotificationSeen(getCookie(SIGNUP_NOTIFICATION_SEEN) === 'true');
  }, [getCookie]);

  useEffect(() => {
    // istanbul ignore else
    if (isSignUpHovered) {
      // hover state
      setSegmentRange([225, 230]);
    } else if (signupNotificationSeen || isButtonFillAnimationComplete) {
      // ending (non hovered) state
      setSegmentRange([210, 225]);
    } else if (isPanelAnimationComplete) {
      // purple fill animation
      setSegmentRange([isMobile ? 110 : 150, 190]);
    }
  }, [
    isMobile,
    isPanelAnimationComplete,
    isButtonFillAnimationComplete,
    isSignUpHovered,
    setSegmentRange,
    signupNotificationSeen,
  ]);

  useEffect(() => {
    let startDelayTimer;
    if (signupNotificationSeen) {
      setDelayed(false);
    } else {
      startDelayTimer = setTimeout(
        /* istanbul ignore next */ () => {
          setDelayed(false);
        },
        1000,
      );
    }

    return () => clearTimeout(startDelayTimer);
  }, [signupNotificationSeen]);

  const onSignUpAnimationComplete = () => {
    if (!isButtonOutlineAnimationComplete) {
      setButtonOutlineAnimationComplete(true);
    } else {
      setButtonFillAnimationComplete(true);
    }
  };

  const onPanelCompleted = () => {
    setPanelAnimationComplete(true);
  };

  return (
    <div className={s.root}>
      <button
        onClick={() => {
          trackClickEvent({
            destination_url: REGISTER,
            label: 'Sign Up',
            location: 'Nav Bar',
            vertical_index: 0,
            horizontal_index: 0,
          });
          router.push(REGISTER);
        }}
        aria-label="Sign Up"
        type="button"
        name="Sign Up"
        className={s.animatedSignupBtn}
        data-testid="wizarding_world_signup"
        onMouseEnter={() => setSignUpHovered(true)}
        onMouseLeave={() => setSignUpHovered(false)}
        onFocus={() => setSignUpHovered(true)}
        onBlur={() => setSignUpHovered(false)}
      >
        <RenderAnimation
          ariaLabel="Sign Up"
          animation="signUp"
          animationData={isMobile ? buttonAnimationMobile : buttonAnimation}
          autoplay
          loop={false}
          speed={1}
          isStopped={isDelayed}
          segmentsEnabled
          segments={segmentRange}
          complete={onSignUpAnimationComplete}
        />
      </button>
      {isButtonOutlineAnimationComplete && !isSearching && !isPanelAnimationComplete && (
        <SignUpPanel
          onComplete={onPanelCompleted}
          signupNotificationSeen={signupNotificationSeen}
          isSignupBtnHovered={isSignUpHovered}
        />
      )}
    </div>
  );
};

export default SignUpBtn;
