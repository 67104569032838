import React from 'react';
import PropTypes from 'prop-types';
import { trackClickEvent } from '@utils/analytics';
import cx from 'classnames';
import s from './SocialShareLinks.module.scss';

const propTypes = {
  socialMediaItems: PropTypes.array,
  analyticsParams: PropTypes.shape({
    location: PropTypes.string,
    verticalIndex: PropTypes.number,
  }).isRequired,
  mobile: PropTypes.bool.isRequired,
};

const defaultProps = {
  socialMediaItems: null,
};

const SocialShareLinks = ({ socialMediaItems, analyticsParams, mobile }) => {
  const defaultSocialMediaItems = [
    {
      displayText: 'Youtube',
      url: 'https://www.youtube.com/channel/UChPRO1CB_Hvd0TvKRU62iSQ',
      image: {
        image: {
          file: {
            url: '/images/components/SocialShareLinks/youtube.svg',
          },
        },
      },
    },
    {
      displayText: 'Tiktok',
      url: 'https://www.tiktok.com/@harrypotter',
      image: {
        image: {
          file: {
            url: '/images/components/SocialShareLinks/tiktok.svg',
          },
        },
      },
    },
    {
      displayText: 'Facebook',
      url: 'https://www.facebook.com/wizardingworld',
      image: {
        image: {
          file: {
            url: '/images/components/SocialShareLinks/facebook.svg',
          },
        },
      },
    },
    {
      displayText: 'Instagram',
      url: 'https://www.instagram.com/harrypotter',
      image: {
        image: {
          file: {
            url: '/images/components/SocialShareLinks/instagram.svg',
          },
        },
      },
    },
    {
      displayText: 'X',
      url: 'https://twitter.com/wizardingworld',
      image: {
        image: {
          file: {
            url: '/images/components/SocialShareLinks/X.svg',
          },
        },
      },
    },
  ];
  const socialIcons = socialMediaItems || defaultSocialMediaItems;

  const onSocialIconClick = (socialLink, index) => {
    trackClickEvent({
      destination_url: socialLink.url,
      label: socialLink.displayText,
      horizontal_index: index,
      ...analyticsParams,
    });
  };

  return (
    <div className={cx(s.socialShareLinks, mobile ? s.mobile : '')}>
      {socialIcons &&
        socialIcons.map((item, index) => (
          <a
            href={item.url}
            className={s.socialLink}
            target="_blank"
            rel="noreferrer"
            key={item.displayText}
            onClick={() => {
              onSocialIconClick(item, index);
            }}
            data-testid={`social-link-${item.displayText}`}
          >
            <img src={item?.image?.image?.file?.url} alt={item.displayText} />
          </a>
        ))}
    </div>
  );
};
SocialShareLinks.propTypes = propTypes;
SocialShareLinks.defaultProps = defaultProps;
export default SocialShareLinks;
